<template>
	<div class="inbox-header with-filters sticky top-0 z-50">
		<div class="inbox-title">
			<span class="inbox-title-label">
				{{ partner ? partner.name : 'Calls' }}
			</span>
			<span
				v-if="!hasRecentCalls"
				class="inbox-title-badge"
				data-tooltip="New Calls"
				data-position="left"
			>
				{{ newCallCount }}
			</span>
			<button
				v-else
				class="inbox-title-badge"
				:data-tooltip="`${recentCallCount} New Call(s) Received`"
				data-position="left"
				@click.prevent="removeAllRecentCalls"
			>
				{{ newCallCount }}
				<font-awesome-icon
					:icon="['fas', 'circle']"
					class="new-notification"
				/>
				<font-awesome-icon
					:icon="['fas', 'circle']"
					class="animate-ping new-notification-ping"
				/>
			</button>
		</div>
		<div class="call-filters">
			<call-filter-dropdown :call-types="callTypes" />
			<provider-filter-dropdown />
		</div>
		<div class="chip-set px-2">
			<chip v-if="!hasFilter && !hasProviderFilter" condensed>
				All
			</chip>
			<chip v-if="urgent" condensed>
				Urgent
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveUrgentFilter"
					/>
				</template>
			</chip>
			<chip v-if="nonUrgent" condensed>
				Non-Urgent
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveNonUrgentFilter"
					/>
				</template>
			</chip>
			<chip v-for="item in filters" :key="item" condensed>
				{{ item | toFilterName }}
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveFilter(item)"
					/>
				</template>
			</chip>
			<chip v-for="type in types" :key="type.id" condensed>
				{{ type.name }}
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveFilterType(type)"
					/>
				</template>
			</chip>
		</div>
		<div class="p-2">
			<div class="flex flex-row">
				<div :key="activePartnerId" class="w-full flex relative">
					<button
						type="button"
						class="dashboard-filter-input-icon calls-page-date-filter-icon"
						@click.prevent="onDateFilterClick"
					>
						<font-awesome-icon :icon="['fal', 'calendar-alt']" />
					</button>
					<flat-pickr
						id="dateRange"
						ref="datePicker"
						:key="omdDateRange"
						v-model="dateFilter"
						:config="configs.range"
						type="text"
						name="dateRange"
						class="form-input dashboard-filter-input calls-page-date-filter m-0"
						:placeholder="`Last ${omdDateRange || 90} days`"
					/>
					<button
						v-if="hasDateFilter"
						type="button"
						class="btn btn-sm dashboard-filter-btn calls-page-date-filter-btn"
						data-position="left"
						@click.prevent="onRemoveDateFilter"
					>
						<font-awesome-icon
							class="btn-icon"
							:icon="['fal', 'times']"
						/>
					</button>
				</div>
			</div>
		</div>
		<div class="w-100 flex justify-between">
			<button
				type="button"
				aria-label="Sort dates button"
				class="btn-icon-button flex items-center justify-center p-0 h-25"
				@click.prevent="$emit('sort')"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon class="btn-icon" :icon="['fad', sortIcon]" />
			</button>
			<font-awesome-icon
				v-if="removingFilter"
				spin
				fixed-width
				size="1x"
				margin="0 0 0 10px"
				:icon="['far', 'spinner-third']"
			/>
			<div v-else class="inbox-header-subtitle w-100 justify-end px-2">
				{{ count || 0 }} / {{ total }} records
			</div>
		</div>
		<loader
			v-if="!hasListLoader && isLoading"
			:enabled="isLoading"
			:threshold="0.65"
			style="margin: 0 !important; border-top: 1px solid #cbd5e0;"
		>
			Please wait...
			<font-awesome-icon
				v-if="isLoading"
				class="ml-2"
				:icon="['far', 'circle-notch']"
				spin
			/>
		</loader>
	</div>
</template>
<script>
import moment from 'moment'
import Chip from '@/components/Chip'
import 'flatpickr/dist/flatpickr.css'
import FlatPickr from 'vue-flatpickr-component'
import CallFilterDropdown from './CallFilterDropdown'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProviderFilterDropdown from './ProviderFilterDropdown'
import Loader from '@/components/Loader.vue'

/**
 * Constant representing the timestamp format the API uses.
 *
 * @type {String}
 */
const API_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Loader,
		Chip,
		CallFilterDropdown,
		FlatPickr,
		ProviderFilterDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if a date filter has been applied.
		 *
		 * @return {Boolean}
		 */
		hasDateFilter() {
			return this.range.length === 2
		},

		/**
		 * Get the sort icon based on the current sort direction.
		 *
		 * @return {String}
		 */
		sortIcon() {
			if (this.sort === 'asc') {
				return 'sort-amount-down'
			}

			return 'sort-amount-up'
		},

		/**
		 * Get the total number of models available (or total in the store).
		 *
		 * @return {Number}
		 */
		total() {
			if (this.count > this.meta.total) {
				return this.count || 0
			}

			return this.meta.total || 0
		},

		...mapGetters({
			callTypes: 'callTypes',
			count: 'calls/count',
			recentCallCount: 'calls/recentCallCount',
			meta: 'calls/meta',
			hasFilter: 'calls/hasFilter',
			hasProviderFilter: 'calls/hasProviderFilter',
			hasRecentCalls: 'calls/hasRecentCalls',
			partner: 'partner',
		}),

		...mapState({
			types: state => state.calls.filters.types,
			filters: state => state.calls.filters.statuses,
			urgent: state => state.calls.filters.urgent,
			nonUrgent: state => state.calls.filters.nonUrgent,
			newCallCount: state => state.calls.incomplete.total,
			omdDateRange: state => state.auth.user.omd_date_range,
			activePartnerId: state => state.partners.active,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the date filter click event.
		 *
		 * @return {void}
		 */
		onDateFilterClick() {
			const flatPicker = this.$refs.datePicker.fp

			if (!flatPicker.isOpen) {
				flatPicker.open()
				flatPicker.close()
				return flatPicker.open()
			}

			flatPicker.close()
		},

		async onDateChange(selectedDates, dateStr, instance) {
			const next90Days = moment(selectedDates[0]).add(
				this.omdDateRange - 1 || 89,
				'days'
			)
			instance.config.maxDate = moment.min(next90Days, moment()).toDate()
			instance.config.minDate = moment(selectedDates[0]).toDate()
			instance?.selectedDateElem?.focus()
			this.onDateRangeChange(selectedDates)
		},
		async onClose(selectedDates, dateStr, instance) {
			if (instance) {
				instance.config.minDate = undefined
				instance.config.maxDate = moment().toDate()
			}
			if (selectedDates && selectedDates.length === 1) {
				instance.config.maxDate = moment().toDate()
			}
		},

		/**
		 * Handle the date range change event.
		 *
		 * @return {void}
		 */
		async onDateRangeChange(range) {
			if (range.length !== 2) {
				return
			}

			this.range = range
			const start = moment(range[0]).format('YYYY-MM-DD')
			const end = moment(range[1]).format('YYYY-MM-DD')

			const startDate = moment(start)
				.startOf('day') // Set time to 00:00:00
				.utc()
				.format(API_FORMAT)

			const endDate = moment(end)
				.endOf('day') // Set time to 23:59:59
				.utc()
				.format(API_FORMAT)

			try {
				await this.setLoading(true)
				await this.setFilters({
					starts: startDate,
					ends: endDate,
				})

				await this.getCallTypesCount()
				await this.get()
			} catch (e) {
				await this.setLoading(false)
			} finally {
				await this.setLoading(false)
			}
		},

		/**
		 * Handle the remove date filter click event.
		 *
		 * @return {void}
		 */
		async onRemoveDateFilter() {
			this.range = []
			this.dateFilter = null
			const flatPicker = this.$refs.datePicker.fp
			flatPicker.open()

			try {
				await this.setLoading(true)
				await this.removeDateRangeFilter()
				if (flatPicker && flatPicker.isOpen) {
					flatPicker.close()
				}
				await this.getCallTypesCount()
				await this.get()
			} catch (e) {
				await this.setLoading(false)
			} finally {
				await this.setLoading(false)
			}
		},

		/**
		 * Remove a filter and refresh the filtered calls.
		 *
		 * @return {void}
		 */
		async onRemoveFilter(filter) {
			this.removingFilter = true
			await this.removeFilter(filter)
			await this.get().then(() => {
				this.removingFilter = false
			})
		},

		/**
		 * Remove a call type filter and refresh the filtered calls.
		 *
		 * @return {void}
		 */
		async onRemoveFilterType(filter) {
			this.removingFilter = true
			await this.removeCallTypeFilter(filter)
			await this.get().then(() => {
				this.removingFilter = false
			})
		},

		/**
		 * Remove the urgent filter and refresh the filtered calls.
		 *
		 * @return {void}
		 */
		async onRemoveUrgentFilter() {
			this.removingFilter = true
			await this.setUrgentFilter(false)
			await this.get().then(() => {
				this.removingFilter = false
			})
		},

		/**
		 * Remove the non-urgent filter and refresh the filtered calls.
		 *
		 * @return {void}
		 */
		async onRemoveNonUrgentFilter() {
			this.removingFilter = true
			await this.setNonUrgentFilter(false)
			await this.get().then(() => {
				this.removingFilter = false
			})
		},

		...mapActions('calls', {
			get: 'get',
			removeFilter: 'removeFilter',
			setUrgentFilter: 'setUrgentFilter',
			setNonUrgentFilter: 'setNonUrgentFilter',
			removeAllRecentCalls: 'removeAllRecentCalls',
			removeCallTypeFilter: 'removeCallTypeFilter',
			removeDateRangeFilter: 'removeDateRangeFilter',
			setFilters: 'setFilters',
			setLoading: 'setLoading',
		}),
		...mapActions({ getCallTypesCount: 'callTypes/get' }),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboxHeader',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current sort direction.
		 *
		 * @type {String}
		 */
		sort: {
			type: String,
			default: 'asc',
		},
		isLoading: Boolean,
		hasListLoader: Boolean,
	},

	watch: {
		activePartnerId: {
			handler(newValue, oldValue) {
				if (newValue && oldValue && newValue !== oldValue) {
					this.range = []
					this.dateFilter = null
				}
			},
			immediate: true,
		},
	},

	beforeDestroy() {
		this.range = []
		this.dateFilter = null
		this.removeDateRangeFilter()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		const today = new Date().setDate(new Date().getDate())
		return {
			configs: {
				range: {
					allowInput: false,
					altInput: true,
					altFormat: 'm/d/Y',
					dateFormat: 'n/j/Y',
					mode: 'range',
					onChange: this.onDateChange,
					onClose: this.onClose,
					wrap: true,
					maxDate: today,
				},
			},
			dateFilter: null,
			isDateFilterOpen: false,
			removingFilter: false,
			range: [],
		}
	},
}
</script>
