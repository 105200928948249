<template>
	<svg
		height="22"
		width="20"
		fill="none"
		viewBox="0 0 20 22"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.3641 0.0854042C16.2689 0.130892 16.0908 0.308322 15.9686 0.47966C15.6889 0.871952 15.6642 1.54905 15.9121 2.02878L16.0771 2.34808H14.0994C12.343 2.34808 12.0899 2.36822 11.838 2.52757C11.5155 2.73163 11.25 3.2422 11.25 3.65798C11.25 4.07729 11.4946 4.60682 11.7846 4.8154C12.025 4.98841 12.2649 5 15.6215 5C19.0262 5 19.2151 4.99057 19.4686 4.80813C19.931 4.47557 20.1386 3.65621 19.9008 3.10299C19.7687 2.79578 17.6635 0.298694 17.3951 0.130793C17.1495 -0.0227633 16.638 -0.0452613 16.3641 0.0854042ZM18.1809 2.12575C18.8644 2.93057 19.3269 3.55197 19.3269 3.66535C19.3269 3.76988 19.2649 3.93621 19.1891 4.03504C19.0599 4.20353 18.8394 4.21473 15.6503 4.21473C12.3602 4.21473 12.2438 4.20834 12.0827 4.01824C11.9911 3.91017 11.9161 3.75544 11.9161 3.67438C11.9161 3.59333 11.9911 3.4386 12.0827 3.33053C12.2415 3.14317 12.3602 3.13404 14.6473 3.13404C16.4015 3.13404 17.0722 3.1024 17.1453 3.01614C17.3385 2.78822 17.2444 2.487 16.8289 2.00422C16.3917 1.4961 16.3134 1.20196 16.5434 0.930506C16.8181 0.606494 17.0093 0.7461 18.1809 2.12575Z"
			fill="#805AD5"
			fillRule="evenodd"
		/>
		<path
			d="M18.1809 2.12575C18.8644 2.93057 19.3269 3.55197 19.3269 3.66535C19.3269 3.76988 19.2649 3.93621 19.1891 4.03504C19.0599 4.20353 18.8394 4.21473 15.6503 4.21473C12.3602 4.21473 12.2438 4.20834 12.0827 4.01824C11.9911 3.91017 11.9161 3.75544 11.9161 3.67438C11.9161 3.59333 11.9911 3.4386 12.0827 3.33053C12.2415 3.14317 12.3602 3.13404 14.6473 3.13404C16.4015 3.13404 17.0722 3.1024 17.1453 3.01614C17.3385 2.78822 17.2444 2.487 16.8289 2.00422C16.3917 1.4961 16.3134 1.20196 16.5434 0.930506C16.8181 0.606494 17.0093 0.7461 18.1809 2.12575Z"
			fill="#805AD5"
		/>
		<path
			d="M14.8859 11.1646C14.9811 11.1191 15.1592 10.9417 15.2814 10.7703C15.5611 10.3781 15.5858 9.70095 15.3379 9.22122V9.22122C15.2627 9.07558 15.3684 8.90193 15.5323 8.90193L17.1506 8.90193C18.907 8.90193 19.1601 8.88179 19.412 8.72244C19.7345 8.51838 20 8.0078 20 7.59203C20 7.17272 19.7554 6.64318 19.4654 6.43461C19.225 6.2616 18.9851 6.25001 15.6285 6.25001C12.2238 6.25001 12.0349 6.25944 11.7814 6.44188C11.319 6.77444 11.1114 7.5938 11.3492 8.14702C11.4813 8.45423 13.5865 10.9513 13.8549 11.1192C14.1005 11.2728 14.612 11.2953 14.8859 11.1646ZM13.0691 9.12426C12.3856 8.31944 11.9231 7.69804 11.9231 7.58466C11.9231 7.48013 11.9851 7.3138 12.0609 7.21497C12.1901 7.04648 12.4106 7.03528 15.5997 7.03528C18.8898 7.03528 19.0062 7.04166 19.1673 7.23177C19.2589 7.33984 19.3339 7.49457 19.3339 7.57562C19.3339 7.65668 19.2589 7.81141 19.1673 7.91948C19.0085 8.10683 18.8898 8.11597 16.6027 8.11597C14.8485 8.11597 14.1778 8.1476 14.1047 8.23386C13.9115 8.46179 14.0056 8.76301 14.4211 9.24578C14.8583 9.75391 14.9366 10.0481 14.7066 10.3195C14.4319 10.6435 14.2406 10.5039 13.0691 9.12426Z"
			fill="#805AD5"
			fillRule="evenodd"
		/>
		<path
			d="M13.0691 9.12426C12.3856 8.31944 11.9231 7.69804 11.9231 7.58466C11.9231 7.48013 11.9851 7.3138 12.0609 7.21497C12.1901 7.04648 12.4106 7.03528 15.5997 7.03528C18.8898 7.03528 19.0062 7.04166 19.1673 7.23177C19.2589 7.33984 19.3339 7.49457 19.3339 7.57562C19.3339 7.65668 19.2589 7.81141 19.1673 7.91948C19.0085 8.10683 18.8898 8.11597 16.6027 8.11597C14.8485 8.11597 14.1778 8.1476 14.1047 8.23386C13.9115 8.46179 14.0056 8.76301 14.4211 9.24578C14.8583 9.75391 14.9366 10.0481 14.7066 10.3195C14.4319 10.6435 14.2406 10.5039 13.0691 9.12426Z"
			fill="#805AD5"
		/>
		<path
			d="M18.9159 14.1406L14.9476 12.4395C14.5913 12.288 14.1956 12.2559 13.8196 12.3482C13.4437 12.4404 13.1077 12.652 12.862 12.9512L11.5847 14.5137C9.53564 13.3969 7.8515 11.7137 6.73355 9.66525L8.29604 8.38791C8.59522 8.14198 8.80674 7.8058 8.89898 7.42966C8.99122 7.05352 8.95922 6.65763 8.80776 6.30119L7.10933 2.33557C6.9454 1.94965 6.65067 1.63386 6.27694 1.44375C5.90322 1.25364 5.47444 1.20136 5.06599 1.29612L1.38672 2.14417C0.991934 2.23376 0.639455 2.45519 0.387379 2.77195C0.135303 3.08872 -0.00132689 3.48192 9.71418e-06 3.88674C9.71418e-06 13.5063 7.79995 21.2539 17.3655 21.25C17.7703 21.2512 18.1634 21.1145 18.48 20.8623C18.7966 20.6102 19.0179 20.2577 19.1073 19.8629L19.9549 16.1824C20.0484 15.7743 19.9955 15.3463 19.8056 14.9731C19.6157 14.6 19.3008 14.3053 18.9159 14.1406ZM17.2913 19.3785C8.79213 19.3395 1.91406 12.461 1.875 3.95706L5.41755 3.14065L7.06168 6.97736L4.40193 9.15353C6.21403 13.0207 8.23745 15.0375 12.0972 16.8488L14.2726 14.1883L18.1081 15.8332L17.2913 19.3785Z"
			fill="#805AD5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CallTransferIcon',
}
</script>
